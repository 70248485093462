import { useEffect, useState } from 'react'
import { Image } from 'antd'
import styles from '../Pages.module.css'

export const Banquet = (): JSX.Element => {
    const [images, setImages] = useState<string[]>([])
    
    useEffect(() => {
        let imageCount = 40
        const imageArr = []
        for (let i = imageCount; i >= 1; i--) {
                imageArr.push(`IMG_${i}`)
            // } else if (i > 100) {
            //     imageArr.push(`0${i}`)
            // } else {
            //     imageArr.push(i.toString())
            // }
        }
        setImages(imageArr)
    }, [])

    return (
        <div className={styles.pagePadding}>
            <div className={styles.titleText}>Banquet</div>
            <div className={styles.flexRowCenterWrap100}>
            <Image.PreviewGroup
                preview={{
                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
            >
                {images.map(p => (
                    // <img key={`img${p}`} src={require(`../../images/banquet/${p}.jpg`)} alt={`img${p}`} className={styles.imageStyle2}/>
                    <Image
                        width={200}
                        className={styles.imageStyle2}
                        src={require(`../../images/banquet/${p}.jpg`)}
                    />
                ))}
                </Image.PreviewGroup>
            </div>
        </div>
    )
}
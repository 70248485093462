import { useEffect, useState } from 'react'
import { Image } from 'antd'
import styles from '../Pages.module.css'

export const Gallery = (): JSX.Element => {
    
    const [images, setImages] = useState<string[]>([])
    
    useEffect(() => {
        let imageCount = 40
        const imageArr = []
        for (let i = imageCount; i >= 1; i--) {
            if (i < 10) {
                imageArr.push(`00${i}`)
            } else if (i < 100) {
                imageArr.push(`0${i}`)
            } else {
                imageArr.push(i.toString())
            }
        }
        setImages(imageArr)
    }, [])

    return (
        <div className={styles.pagePadding}>
            <div className={styles.titleText}>Gallery</div>
            <div className={styles.flexRowCenterWrap100}>
                <Image.PreviewGroup
                    preview={{
                    onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                    }}
                >
                    {images.map(p => (
                        // <img key={`img${p}`} src={require(`../../images/img${p}.jpeg`)} alt={`img${p}`} className={styles.imageStyle}/>
                        <Image
                            width={200}
                            className={styles.imageStyle}
                            src={require(`../../images/img${p}.jpeg`)}
                        />
                    ))}
                </Image.PreviewGroup>
            </div>
            <div className={styles.flexRowCenterWrap100} style={{marginTop: '30px'}}>
                Submit your club photos for the website to &nbsp;
                <a href='sarah.iswac@gmail.com'>
                    sarah.iswac@gmail.com
                </a>
            </div>
        </div>
    )
}